<template>
  <div class="canvsbox">
    <canvas ref="myCanvs" width="600" height="400"></canvas>
  </div>
</template>
<script>
export default {
  name: "cake",
  props: {
    list: {
      type: Array,
      default: () => []
    }
  },
  data () {
    return {};
  },
  methods: {
    init () {
      var canvas = this.$refs.myCanvs;
      function PieChart (data) {
        this.ctx = canvas.getContext("2d");
        console.log('画布初始化', this.ctx.width);
        canvas.getContext("2d").clearRect(0, 0, this.ctx.canvas.width, this.ctx.canvas.height);
        this.x0 = this.ctx.canvas.width / 2;
        this.y0 = this.ctx.canvas.height / 2;
        //获取半径
        this.radius = 150;
        //标题伸出去的距离
        this.outLine = 20;
        //小矩形距横向间距
        this.spaceX = 10;
        //小矩形距纵向间距
        this.spaceY = 20;
        //小矩形的宽度
        this.smallW = 30;
        //小矩形的宽度
        this.smallH = 15;
        this.data = data;
      }
      //初始化饼状图
      PieChart.prototype.init = function () {
        this.drawPie();
      };
      //添加饼图
      PieChart.prototype.drawPie = function () {
        //1、将数据转为弧度
        var angleList = this.drawAngle();
        var start = 0; //起始弧度
        angleList.forEach(
          function (item) {
            var end = item.angle + start;
            this.ctx.beginPath();
            this.ctx.moveTo(this.x0, this.y0);
            this.ctx.arc(this.x0, this.y0, this.radius, start, end);
            this.ctx.fillStyle = item.color;
            this.ctx.fill();

            //调用标的方法
            this.drawTitle(start, item, this.ctx.fillStyle);

            //调用画小矩形
            // this.drawInfo(i,item.title,this.ctx.fillStyle);

            //记录上一个结束的弧度
            start = end;
          }.bind(this)
        );
      };
      //添加标题
      PieChart.prototype.drawTitle = function (start, item, color) {
        //斜边的长度
        var edge = this.radius + this.outLine;
        //x轴方向的直角边
        var edgeX = edge * Math.cos(start + item.angle / 2);
        //y轴方向的直角边
        var edgeY = edge * Math.sin(start + item.angle / 2);
        //伸出去的横纵坐标
        var outX = this.x0 + edgeX;
        var outY = this.y0 + edgeY;
        this.ctx.beginPath();
        this.ctx.moveTo(this.x0, this.y0);
        this.ctx.lineTo(outX, outY);
        this.ctx.strokeStyle = color;
        this.ctx.stroke();

        //添加字体
        var align = outX > this.x0 ? "left" : "right";
        this.ctx.font = "15px 微软雅黑";
        this.ctx.textAlign = align;
        this.ctx.textBaseline = "bottom";
        this.ctx.fillStyle = color;
        this.ctx.fillText(item.title + parseInt(item.num / 60) + "小时" + item.num % 60 + '分钟', outX, outY);

        //添加文字下面的线
        var textW = this.ctx.measureText(item.title + parseInt(item.num / 60) + "小时" + item.num % 60 + '分钟').width;
        this.ctx.moveTo(outX, outY);
        outX = outX > this.x0 ? outX + textW : outX - textW;
        this.ctx.lineTo(outX, outY);
        this.ctx.stroke();
      };
      //添加说明
      PieChart.prototype.drawInfo = function (index, text, color) {
        this.ctx.beginPath();
        //画小矩形
        this.ctx.fillRect(
          this.spaceX,
          this.spaceY * index + this.smallH,
          this.smallW,
          this.smallH
        );
        //画文字
        this.ctx.font = "12px 微软雅黑";
        this.ctx.fillStyle = color;
        this.ctx.textAlign = "left";
        this.ctx.fillText(
          text,
          this.spaceX * 2 + this.smallW,
          this.spaceY * index + this.smallH * 2
        );
      };
      //添加转化弧度的方法
      PieChart.prototype.drawAngle = function () {
        //先获取数据的总和
        var total = 0;
        console.log(this);
        this.data.forEach(function (item) {
          total += item.num;
        });
        //获取每条数据的弧度
        this.data.forEach(function (item) {
          var angle = (item.num / total) * Math.PI * 2;
          item.angle = angle;
        });
        return this.data;
      };
      if (this.list.length > 0) {
        var pieChart = new PieChart(this.list);
        pieChart.init();
      }
    }
  },
  mounted () {

    this.init();
  },
  watch: {
    list () {

      this.init();
    }
  }
};
</script>
<style lang="scss" scoped>
.canvsbox {
  display: inline-block;
  width: 600px;
  height: 400px;
  margin-right: 30px;
  max-width: 100%;
}
.canvsbox canvas {
  width: 100%;
  height: 100%;
  transition: all 0.5s;
  -webkit-transition: all 0.5s;
  -moz-transition: all 0.5s;
  -o-transition: all 0.5s;
  -ms-transition: all 0.5s;
}
.canvsbox canvas:hover {
  transform: scale(1.2, 1.2);
  -moz-transform: scale(1.2, 1.2);
  -ms-transform: scale(1.2, 1.2);
  -o-transform: scale(1.2, 1.2);
  -webkit-transform: scale(1.2, 1.2);
}
@media screen and (max-width: 750px) {
  .canvsbox {
    display: inline-block;
    width: 90vw;
    height: 60vw;
    margin-right: 30px;
    max-width: 100%;
  }
}
</style>