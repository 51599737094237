<template>
  <div class="main-conent main-conent-minheight">
    <el-card class="box-card status">

      <div class="block" style="line-height: 40px;">
        <!-- <el-button style="float:left;" type="primary" @click="onjk" round>返回监控模式</el-button> -->
        <span class="demonstration">{{iot.nick_name}}</span>
        <el-date-picker value-format="yyyy-MM-dd" :picker-options="pickerOptions0" v-model="value1" type="date" :clearable="false" :change="cheangedate()" placeholder="选择日期"></el-date-picker>
        <!-- <el-date-picker value-format="yyyy-MM-dd HH:mm:ss" v-model="value2" type="datetimerange" :picker-options="pickerOptions" :change="cheangedate()" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" align="right">
        </el-date-picker> -->
        <el-time-select :change="cheangedate()" style="    margin-left: 10px; width: 150px;" placeholder="起始时间" v-model="startTime" :picker-options="{
      start: '00:00',
      step: '00:15',
      end: '23:30'
    }">
        </el-time-select>
        <el-time-select style="    margin-left: 10px; 
    width: 150px;" placeholder="结束时间" v-model="endTime" :picker-options="{
      start: '00:00',
      step: '00:15',
      end: vend,
      minTime: startTime
    }">
        </el-time-select>
        <span @click="getdate" style="cursor:pointer;margin-left: 30px;color: #2a88b7;" class="cx">查询</span>
        <!-- <span style=" margin-left: 30px;">开机时段：7:00~19:00</span> -->
        <span style="  float: right;color: #2a88b7;" @click="tz">查看月统计></span>
      </div>
      <div class="block" style="margin-top: 50px;">
        <strip :list="strList" :end="end"></strip>
      </div>
      <div class="block" style=" margin-top: 50px;">
        <cake :list="list"></cake>
        <p>今日开机率：{{(iot.total_online_time/(iot.total_online_time+iot.total_off_time) *100).toFixed(2)}}%</p>
      </div>
    </el-card>
  </div>
</template>
<script>
import cake from "@/components/echarts/cake";
import strip from "@/components/echarts/strip";
import { one } from "@/service/api";
export default {
  data () {
    return {
      pickerOptions0: {
        disabledDate (time) {
          //8.64e7
          // return time.getTime() < Date.now() - 8.64e7; //只能选择今天及今天之后
          //return time.getTime() > Date.now() - 8.64e7; //只能选择今天之前，连今天都不能选

          //8.64e6
          // return time.getTime() < Date.now() - 8.64e6; //只能选择今天之后，连今天都不能选
          return time.getTime() > Date.now(); // 选当前时间之前的时间
        }
      },
      pickerOptions: {
        shortcuts: [{
          text: '最近一周',
          onClick (picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '最近一个月',
          onClick (picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '最近三个月',
          onClick (picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
            picker.$emit('pick', [start, end]);
          }
        }]
      },
      value1: new Date().toISOString().substring(0, 10),
      value2: '',
      list: [],
      strList: [
      ],

      id: "",
      iot: "",
      startTime: '',
      endTime: '',
      vend: this.value1 === new Date().toISOString().substring(0, 10) ? new Date().getHours() + ":" + new Date().getMinutes() : '24:00',
      end: {
        start: '00:00',
        end: '23:59'
      }
    };
  },
  components: {
    cake,
    strip
  },
  computed: {},
  watch: {},
  methods: {
    onjk () {
      this.$router.push('/home');
    },
    tz () {
      console.log(this.iot.company_group_id);
      this.$router.push('/yue?id=' + this.iot.company_group_id);

    },

    cheangedate () {



    },
    cheangedate2 () {
      console.log(this.startTime, this.endTime);

    },
    getdate () {
      if (this.id) {
        let data = {};
        console.log(this.startTime, '---' + this.endTime);
        if (this.startTime) {
          console.log(this.value1 + ' ' + this.startTime + ':00');
          if (this.endTime === '' || this.endTime === null) {
            this.endTime = '23:59';
          }

          if (this.value1 === new Date().toISOString().substring(0, 10)) {

            let endH = Number(this.endTime.slice(0, 2));
            let endm = Number(this.endTime.slice(3, 5));
            let min = new Date().getMinutes() < 10 ? '0' + new Date().getMinutes() : new Date().getMinutes();
            console.log('------');
            console.log(endH, endm);
            if (endH > new Date().getHours()) {
              this.endTime = new Date().getHours() + ':' + min;

            } else if (endH === new Date().getHours()) {
              if (endm > min) {
                this.endTime = new Date().getHours() + ':' + min;
              }
            }



          }
          data = {
            iot_id: this.id,
            log_start_date: this.value1 + ' ' + this.startTime + ':00',
            log_end_date: this.value1 + ' ' + this.endTime + ':00'


          };


        } else {
          data = {
            iot_id: this.id,
            // log_start_date: this.value2[0],
            // log_end_date: this.value2[1]
            log_date: this.value1

          };

        }
        let that = this;

        one(data).then(res => {
          if (res.data.onlieTimes.length === 0) {
            this.$message.error('此时间内无开机数据');
            this.strList = [];
            // return;
          }
          this.iot = res.data.iot;
          let list = [
            {
              title: "开机",
              num: this.iot.total_online_time,
              color: "#35b72a"
            },
            { title: "停机", num: this.iot.total_off_time, color: "#c8c8c8" }
          ];
          console.log(list);
          this.list = list;
          let strList = res.data.onlieTimes;
          // 
          if (strList.length !== 0) {
            if (strList[strList.length - 1].end !== '23:59') {

              let min = new Date().getMinutes() < 10 ? '0' + new Date().getMinutes() : new Date().getMinutes();
              console.log(new Date().getMinutes());
              strList[strList.length - 1].end = new Date().getHours() + ':' + min;

            }
          }


          // let vaend = this.value2[1].substring(11, 16);
          // if (vaend !== '23:59') {
          //   console.log(this.value2[1].substring(11, 16));
          //   console.log('选择');
          //   this.end.end = this.value2[1].substring(11, 16);
          // } else {
          //   this.end.end = new Date().getHours() + ':' + new Date().getMinutes();
          // }

          // 判断是不是当天计算总时间
          if (this.value1 === new Date().toISOString().substring(0, 10)) {
            let min = new Date().getMinutes() < 10 ? '0' + new Date().getMinutes() : new Date().getMinutes();
            this.end.end = new Date().getHours() + ':' + min;
          } else {
            this.end.end = '23:59';
          }

          if (this.startTime) {


            that.end.end = that.endTime;
            that.end.start = that.startTime;


            console.log(that.end);
          }

          let zong = this.value1 === new Date().toISOString().substring(0, 10) ? new Date().getHours() + ':' + new Date().getMinutes() : '23:59';
          zong = parseInt(zong.substring(0, 2)) * 60 + parseInt(zong.substring(3, 5));
          console.log(zong);
          strList.forEach(item => {
            item.startDate = item.start;
            item.ednDate = item.end;
            item.start = (parseInt(item.start.substring(0, 2)) * 60 + parseInt(item.start.substring(3, 5))) * 100 / zong;
            item.end = (parseInt(item.end.substring(0, 2)) * 60 + parseInt(item.end.substring(3, 5))) * 100 / zong;
          });

          this.strList = strList;
        });
      }
    }
  },
  mounted () {
    let d = new Date().toISOString().substring(0, 10);
    console.log(d + ' 00:00:00');
    this.value2 = [d + ' 00:00:00', d + ' 23:59:59'];
    this.id = this.$route.query.id;
    this.getdate();
  }
};
</script>
<style lang="scss">
.demonstration {
  margin-right: 30px;
  font-size: 28px;
  font-weight: bold;
}
.block {
  text-align: center;
}
@media screen and (max-width: 750px) {
  .main-conent {
    margin: 10px;
  }
  .el-card__body {
    padding: 0;
  }
  .demonstration {
    display: block;
    width: 100%;
    margin-right: 30px;
    font-size: 28px;
    font-weight: bold;
  }
  .el-date-editor.el-input,
  .el-date-editor.el-input__inner {
    width: 92%;
  }
  .el-date-editor--time-select {
    width: 46% !important;
    margin-left: 0px !important;
    // margin-right: 5px;
  }
  .cx {
    float: left;
    margin-left: 20px !important;
  }
  .canvsbox {
    display: none;
  }
  .canvsbox {
  }
}
</style>