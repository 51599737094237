import request from '@/service/lib/request';
//设备列表
export function deviceList(data) {

    return request({
        url: '/device/list?limit=' + data.limit + '&page=' + data.page + '&device_level=2',
        method: 'get',
        data
    });
}
//设备列表
export function deviceListlv1(data) {

    return request({
        url: '/device/list?limit=' + 999 + '&page=' + 1 + '&device_level=1',
        method: 'get',
        data
    });
}

/**
 * 新增设备
 * @param data
 * @returns {*}
 */
// export function deviceAdd(data) {

//     return request({
//         url: '/device/add',
//         method: 'post',
//         data
//     });
// }
export function deviceAdd2(data) {

    return request({
        url: '/device/addV2',
        method: 'post',
        data
    });
}

/**
 * 删除设备
 * @param data
 * @returns {*}
 */
export function deviceDelete(data) {
    return request({
        url: '/device/delete/' + data.id + '/',
        method: 'delete',
        data
    });
}

export function putdevice(data) {

    return request({
        url: '/device/' + data.id + '/',
        method: 'post',
        data
    });
}
export function putdevicev2(data) {

    return request({
        url: '/device/updateV2/' + data.id + '/',
        method: 'post',
        data
    });
}
export function group(data) {

    return request({
        url: '/group',
        method: 'get',
        data
    });
}

export function addgroup(data) {

    return request({
        url: '/group',
        method: 'post',
        data
    });
}

export function putgroup(data) {

    return request({
        url: '/group/' + data.id + '/',
        method: 'POST',
        data
    });
}

export function deletegroup(data) {

    return request({
        url: '/group/' + data.id + '/',
        method: 'delete',
        data
    });
}

export function stoken() {
    return request({
        url: '/auth/update',
        method: 'post'
    });
}

// 详情
export function one(data) {
    return request({
        url: '/device/show',
        method: 'post',
        data
    });
}

export function onelist(data) {
    return request({
        url: '/device/company_device',
        method: 'post',
        data
    });
}

// 详情
export function yue(data) {
    return request({
        url: '/device/runCount',
        method: 'post',
        data
    });
}

// 链接绑定

export function bind(data) {
    return request({
        url: '/auth/bind',
        method: 'post',
        data
    });
}

//设备统计报表
export function report(data) {
    return request({
        url: '/device/report',
        method: 'post',
        data
    });

}

// 客户管理

export function addcustomer(data) {
    return request({
        url: '/customer',
        method: 'post',
        data
    });

}

export function getcustomer(data) {
    return request({
        url: '/customer?limit=' + data.limit + '&page=' + data.page + '&name=' + data.name,
        method: 'get',
        data
    });

}

export function putcustomer(data) {
    return request({
        url: '/customer/' + data.id + '/',
        method: 'PUT',
        data
    });

}

export function deletecustomer(data) {
    return request({
        url: '/customer/' + data.id + '/',
        method: 'delete'
    });

}

// 订单管理

export function addOrder(data) {
    return request({
        url: '/production_order',
        method: 'post',
        data
    });

}

export function getOrder(data) {
    return request({
        url: '/production_order?limit=' + data.limit + '&page=' + data.page + '&name=' + data.name,
        method: 'get'
    });

}

export function deleteOrder(data) {
    return request({
        url: '/production_order/' + data.id + '/',
        method: 'delete'
    });

}

export function putOrder(data) {
    return request({
        url: '/production_order/' + data.id + '/',
        method: 'PUT',
        data
    });


}


// 员工管理

export function addstaff(data) {
    return request({
        url: '/staff',
        method: 'post',
        data
    });
}

export function getstaff(data) {
    let is_subscribe = data.is_subscribe?data.is_subscribe:'';
    return request({
        url: '/staff?limit=' + data.limit + '&page=' + data.page + '&name=' + data.name + '&group_id=' + data.company_group_id +'&is_subscribe='+is_subscribe,
        method: 'get'
    });

}

export function putstaff(data) {
    return request({
        url: '/staff/' + data.id + '/',
        method: 'PUT',
        data
    });
}

export function deletestaff(data) {
    return request({
        url: '/staff/' + data.id + '/',
        method: 'delete'
    });

}

// 生产排程

export function addschedule(data) {
    return request({
        url: '/schedule',
        method: 'post',
        data
    });
}

export function getschedule(data) {
    return request({
        url: '/schedule?limit=' + data.limit + '&page=' + data.page + '&iot_id=' + data.iot_id + '&status=' + data.status + '&start_date=' + data.start_date + '&end_date=' + data.end_date + '&group_id=' + data.group_id,
        method: 'get'
    });

}

export function putschedule(data) {
    return request({
        url: '/schedule/' + data.id + '/',
        method: 'PUT',
        data
    });
}

export function deleteschedule(data) {
    return request({
        url: '/schedule/' + data.id + '/',
        method: 'delete'
    });

}

// 生产报表
export function getreport(data) {
    return request({
        url: '/production/report',
        method: 'post',
        data
    });

}

// 删除
export function deletereport(data) {
    return request({
        url: '/production/report/' + data.id,
        method: 'delete'

    });

}

// 员工报表

export function getStaffReport(data) {
    return request({
        url: '/staff/report',
        method: 'post',
        data
    });

}

export function getreport2(data) {
    return request({
        url: '/production/report',
        method: 'post',
        data
    });

}

export function getreport3(data) {
    return request({
        url: '/staff/month/report',
        method: 'post',
        data
    });

}

// 耗材
export function addConsumable(data) {
    return request({
        url: '/consumable',
        method: 'post',
        data
    });

}

export function getConsumable(data) {
    return request({
        url: '/consumable?limit=' + data.limit + '&page=' + data.page + '&type=' + data.type + '&not_stock=' + data.not_stock,
        method: 'get'
    });

}

export function deletConsumable(data) {
    return request({
        url: '/consumable/' + data.id + '/',
        method: 'delete'
    });

}

export function putConsumable(data) {

    return request({
        url: '/consumable/' + data.id + '/',
        method: 'put',
        data
    });
}

export function logs(data) {
    return request({
        url: '/consumable/logs?limit=' + data.limit + '&page=' + data.page + '&consumable_id=' + data.id,
        method: 'get'
    });

}

// 公司订单管理
export function addCompanyOrder(data) {
    return request({
        url: '/company_order',
        method: 'post',
        data
    });
}

export function getCompanyOrder(data) {
    return request({
        url: '/company_order?limit=' + data.limit + '&page=' + data.page + '&name=' + data.name,
        method: 'get'
    });
}

export function deleteCompanyOrder(data) {
    return request({
        url: '/company_order/' + data.id + '/',
        method: 'delete'
    });
}

export function putCompanyOrder(data) {
    return request({
        url: '/company_order/' + data.id + '/',
        method: 'PUT',
        data
    });
}

// 公司子订单管理
export function addCompanyOrderItem(data) {
    return request({
        url: '/company_order_item',
        method: 'post',
        data
    });
}

export function getCompanyOrderItem(data) {
    return request({
        url: '/company_order_item?limit=' + data.limit + '&page=' + data.page + '&name=' + data.name,
        method: 'get'
    });
}

export function deleteCompanyOrderItem(data) {
    return request({
        url: '/company_order_item/' + data.id + '/',
        method: 'delete'
    });
}

export function putCompanyOrderItem(data) {
    return request({
        url: '/company_order_item/' + data.id + '/',
        method: 'PUT',
        data
    });
}

export function addCompanyOrderProcess(data) {
    return request({
        url: '/company_order_process',
        method: 'post',
        data
    });
}

export function getCompanyOrderProcess(data) {
    return request({
        url: '/company_order_process?limit=' + data.limit + '&page=' + data.page + '&order_item_id=' + data.order_item_id,
        method: 'get'
    });
}

export function deleteCompanyOrderProcess(data) {
    return request({
        url: '/company_order_process/' + data.id + '/',
        method: 'delete'
    });
}

export function putCompanyOrderProcess(data) {
    return request({
        url: '/company_order_process/' + data.id + '/',
        method: 'PUT',
        data
    });
}

// 公司工序模板管理
export function addProcessTemplate(data) {
    return request({
        url: '/process_template',
        method: 'post',
        data
    });
}

export function getProcessTemplate(data) {
    return request({
        url: '/process_template?limit=' + data.limit + '&page=' + data.page + '&name=' + data.name,
        method: 'get'
    });
}

export function deleteProcessTemplate(data) {
    return request({
        url: '/process_template/' + data.id + '/',
        method: 'delete'
    });
}

export function putProcessTemplate(data) {
    return request({
        url: '/process_template/' + data.id + '/',
        method: 'PUT',
        data
    });
}

export function orderProcessSave(data) {
    return request({
        url: '/order_process/save',
        method: 'post',
        data
    });
}

export function operationLogList(data) {
    return request({
        url: '/operationLogList',
        method: 'post',
        data
    });
}

export function orderCountList() {
    return request({
        url: '/statistics/orderCountList',
        method: 'get',
        nolog:'no'
        
    });
}

export function staffTaskList() {
    return request({
        url: '/statistics/staffTaskList',
        method: 'get',
        nolog:'no'
        
    });
}

// 绑定耗材提醒员工
   
export function noticeStaffConfig(data) {
    return request({
        url: '/company/noticeStaffConfig',
        method: 'post',
        data
    });
}

export function getcompany(id) {
    return request({
        url: '/company/'+id,
        method: 'get'
    
    });
}