<template>
  <div class="canvsbox">
    <div class="stripBox">
      <div class="strip">
        <span class="striText start">{{end.start}}</span>
        <span class="striText end">{{end.end}}</span>
        <div style="width: 100%;overflow: hidden;height: 100px;   ">
          <div class="hover" :style="{left:item.start+'%',width:item.end - item.start +'%'}" v-for="(item, index) in list" :key="index">
            <span class="striText ">{{item.startDate}}</span>
            <span class="striText ">{{item.ednDate}}</span>
          </div>
        </div>

      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "strip",
  props: {
    list: {
      type: Array,
      default: () => []
    },
    end: {
      type: Object,
      default: () => { }
    }
  },
  data () {
    return {};
  },
  methods: {},
  mounted () { }
};
</script>
<style lang="scss" scoped>
.stripBox {
  width: 80%;
  margin: 0px auto;
  height: 80px;
}
.strip {
  width: 100%;
  height: 50px;
  position: relative;
  background-color: #c8c8c8;
}
.striText {
  font-size: 14px;
  color: #999999;
  position: absolute;
  bottom: 15px;
  display: inline-block;
  width: 100px;
  height: 50px;
  background: white;
  z-index: 999;
  top: 0;
  line-height: 50px;
  padding: 0 20px;
  box-sizing: border-box;
}
.hover .striText {
  background-color: transparent;
  width: 50px;
  bottom: 0;
  top: initial;
  // display: inline;
  line-height: 100px;
  padding: 0;
}
.stripBox .start {
  left: -100px;

  // transform: translateX(-150%);
  text-align: right;
}
.stripBox .end {
  right: -100px;
  text-align: left;
  // transform: translateX(150%);
}
.stripBox .hover {
  height: 50px;
  background-color: #35b72a;
  min-width: 0.05%;
  // top: 20px;
  bottom: 50%;
  transform: translateY(50%);
  position: absolute;
  z-index: 99;
  transition: all 0.5s;
  -webkit-transition: all 0.5s;
  -moz-transition: all 0.5s;
  -o-transition: all 0.5s;
  -ms-transition: all 0.5s;
}

.stripBox .hover span {
  display: none;
  bottom: -10px;
}
.stripBox .hover span:nth-child(1) {
  left: 0;

  transform: translateX(-120%);
}
.stripBox .hover span:nth-child(2) {
  right: 0;
  transform: translateX(120%);
}
.stripBox .hover:hover {
  height: 80px;
}
.stripBox .hover:hover span {
  opacity: 1;
  display: block;
}
@media screen and (max-width: 750px) {
}
</style>